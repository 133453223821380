import {
    ref,
    reactive
} from 'vue';
import axios from '@/axios';
import store from '@/store';

export default function useOtherFees() {
    const errors = ref([]); //ref: to make it reactive
    const msgs = ref([]);
    const other_fees = ref([]);
    const other_fee = ref([]);
    const student = ref({});
    const lists = reactive({});


    const getOtherFees = async (data = {}) => {
        try {
            let response = await axios.post('/api/other_fees/get', data);
            other_fees.value = response.data.data.other_fees;
            student.value = response.data.data.student;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const getOtherFee = async (id) => {
        try {
            let response = await axios.get('/api/other_fees/' + id + '/edit');
            console.log('response', response);
            other_fee.value = response.data.data.other_fee;
            lists.payment_profiles = response.data.data.payment_profiles;
            lists.installments = response.data.data.installments;
            student.value = response.data.data.student;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const storeOtherFee = async (data) => {
        errors.value = ''; //
        try {
            let response = await axios.post('/api/other_fees/', data);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }

    const updateOtherFee = async (data, id) => {
        errors.value = ''; //
        try {
            data.append("_method", 'PATCH');
            let response = await axios.post('/api/other_fees/' + id, data);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }


    const deleteOtherFee = async (id) => {
        try {
            await axios.delete('/api/other_fees/' + id);
        } catch (e) {
            await store.dispatch("handleException", e);

        }
    }

    const getLists = async (student_id) => {
        //
        try {
            let response = await axios.get('/api/other_fees/git_lists/' + student_id);
            lists.payment_profiles = response.data.data.payment_profiles;
            lists.installments = response.data.data.installments;
            student.value = response.data.data.student;
        } catch (e) {
            await store.dispatch("handleException", e);
        }
    }


    return {
        getOtherFees,
        getOtherFee,
        storeOtherFee,
        updateOtherFee,
        deleteOtherFee,
        getLists,
        other_fees,
        other_fee,
        student,
        lists,
        msgs,
        errors,
    }
}